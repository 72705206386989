const currentYear = new Date().getFullYear();

let financialYears = [];
// If it's not yet September, include the previous three years as the start of the financial year
if (new Date().getMonth() < 8) {
  financialYears.push(currentYear - 2);
  financialYears.push(currentYear - 3);
  financialYears.push(currentYear - 4);
} else {
  // Otherwise, include the current year as the start of the financial year
  financialYears.push(currentYear - 1);
  financialYears.push(currentYear - 2);
  financialYears.push(currentYear - 3);
}

export const nonDependentFieldsArray = [
  {
    reverseCondition: true,
    formStep: 'form_step_1',
    jsonfield: 'is_80g',
    nonDependentFields: ['number_12a', 'certificate_12a']
  }
];
export const dependentFieldsArray = [
  {
    reverseCondition: true,
    formStep: 'form_step_1',
    jsonfield: 'is_80g',
    dependentFields: ['number_80g', 'certificate_80g']
  },
  {
    reverseCondition: true,
    formStep: 'form_step_1',
    jsonfield: 'is_pan',
    dependentFields: ['pan_num', 'pan_doc']
  },
  {
    reverseCondition: false,
    formStep: 'form_step_1',
    jsonfield: 'is_csr1_registered',
    dependentFields: [
      'csr_registration_number',
      // 'csr1_expiry_date',
      'csr1_registration_certificate'
    ]
  },
  {
    reverseCondition: false,
    formStep: 'form_step_1',
    jsonfield: 'is_fcra_certified',
    dependentFields: [
      'fcra_registration_number',
      'fcra_purpose_id',
      'fcra_expiry_date',
      'fcra_certificate'
    ]
  },
  {
    reverseCondition: true,
    title: 'Audited Financial Reports',
    yearFlag: 1,
    formStep: 'form_step_2',
    jsonfield: 'is_report_' + String(financialYears[0]),
    dependentFields: ['report_' + String(financialYears[0])]
  },
  {
    reverseCondition: true,
    title: 'Audited Financial Reports',
    yearFlag: 2,
    formStep: 'form_step_2',
    jsonfield: 'is_report_' + String(financialYears[1]),
    dependentFields: ['report_' + String(financialYears[1])]
  },
  {
    reverseCondition: true,
    title: 'Audited Financial Reports',
    yearFlag: 3,
    formStep: 'form_step_2',
    jsonfield: 'is_report_' + String(financialYears[2]),
    dependentFields: ['report_' + String(financialYears[2])]
  },
  {
    reverseCondition: true,
    formStep: 'form_step_2',
    jsonfield: 'is_tan_num',
    dependentFields: ['tan_num', 'tan_document']
  }
];
export const preRegistrationDataForm1 = [
  {
    title: '12A Registration Details',

    description:
      '12A helps non-profits to avail tax exemptions on their surplus income',
    wrapperKey: 'legal_details',
    step: 'step_1',
    fields: [
      {
        label: '12A Registration Number',
        inputtype: 'text',
        type: 'text',
        showrequired: 'true',
        validation: 'required',
        placeholder: 'e.g. ABC(D)PLC/12A/123/ABCDE1234F/IJK(E)-0/Vol 20XX-20XI',
        validationMsg: 'Please enter valid 12A registration number',
        jsonfield: 'number_12a',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        disableDependentOn: '',
        dependentOnFieldType: '',
        formField: true,
        textContent: 'uppercase'
      },
      {
        label: '12A Registration Document',
        accept:
          '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF,.doc,.docx',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        jsonfield: 'certificate_12a',
        validationMsg: 'Please enter 12A registration document',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        disableDependentOn: '',
        disableRelatedField: '',
        formField: true
      }
      // {
      //   label: 'We do not have 12A Registration',
      //   inputtype: 'tncCheckBox',
      //   jsonfield: 'is_12a',
      //   excludeFromStepCompletionCalculation: true,
      //   dependentFields: ['certificate_12a', 'number_12a'],
      //   // required: true
      //   // validation: 'required'
      //   wrapperKey: 'legal_compliance',
      //   subWrapperKey: '',
      //   formField: true
      // }
    ]
  },
  {
    title: '80G Registration Details',
    description: '80G helps donor to get tax exemption',
    wrapperKey: 'legal_details',
    step: 'step_2',
    fields: [
      {
        label: '80G Registration Number',
        inputtype: 'text',
        type: 'text',
        showrequired: 'true',
        validation: 'required',
        placeholder:
          'e.g., ABC(D)PLC/80G/123/ABCDE1234F/IJK(E)-0/Vol 20XX-20XI',
        validationMsg: 'Please enter valid 80G registration number',
        jsonfield: 'number_80g',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        disableDependentOn: 'is_80g',
        dependentOnFieldType: 'checkbox',
        formField: true,
        textContent: 'uppercase'
      },
      {
        label: '80G Registration Document',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        validationMsg: 'Please enter valid 80G registration document',
        jsonfield: 'certificate_80g',
        dependencywrapperkey: 'legal_compliance',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        disableDependentOn: 'is_80g',
        dependentOnFieldType: 'checkbox',
        disableRelatedField: ['number_80g'],
        formField: true
      },
      {
        label: 'We do not have 80G Registration',
        inputtype: 'tncCheckBox',
        jsonfield: 'is_80g',
        excludeFromStepCompletionCalculation: true,
        dependentFields: ['number_80g', 'certificate_80g'],
        // required: true
        // validation: 'required'
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        formField: true,
        dependentOnFieldType: '',
        disableDependentOn: ''
      }
    ]
  },
  {
    title: 'PAN Details',
    description: '',
    wrapperKey: 'legal_details',
    step: 'step_3',
    fields: [
      {
        label: 'PAN Number',
        inputtype: 'text',
        type: 'text',
        lengthMin: 10,
        maxLength: 10,
        showrequired: 'true',
        validation: 'required',
        placeholder: 'e.g., ABCDE1111F',
        validationMsg: 'Please enter valid PAN number eg. ABCDE1111F',
        jsonfield: 'pan_num',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        disableDependentOn: '',
        dependentOnFieldType: 'checkbox',
        formField: true,
        textContent: 'uppercase'
      },
      {
        label: 'PAN Card - Scanned Copy',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        validationMsg: 'Please enter valid PAN document',
        jsonfield: 'pan_doc',
        dependencywrapperkey: 'legal_compliance',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        disableDependentOn: '',
        dependentOnFieldType: 'checkbox',
        disableRelatedField: ['pan_doc,pan_num'],
        formField: true
      }
    ]
  },
  {
    title: 'Do you have CSR1 registration with MCA?',
    description:
      'CSR1 will hep you get CSR funding from the corporates and undertake CSR activities',
    wrapperKey: 'legal_details',
    step: 'step_4',
    fields: [
      {
        label: '',
        inputtype: 'radio',
        jsonfield: 'is_csr1_registered',
        validationMsg: 'Please enter your option',
        dependentFields: [
          'csr_registration_number',
          // 'csr1_expiry_date',
          'csr1_registration_certificate'
        ],
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        formField: true,
        disableDependentOn: '',
        dependentOnFieldType: ''
      },
      {
        label: 'CSR1 Registration Number',
        type: 'text',
        inputtype: 'text',
        placeholder: `e.g., CSR12345678`,
        validationpattern: /[C]{1}[S]{1}[R]{1}[0-9]{8}$/,
        lengthMin: 11,
        maxLength: 11,
        validpatternformat: 'CSR12345678',
        showrequired: 'true',
        validation: 'required',
        jsonfield: 'csr_registration_number',
        validationMsg:
          'Please enter valid CSR1 registration number in the format eg CSR12345678',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        formField: true,
        textContent: 'uppercase',
        disableDependentOn: 'is_csr1_registered',
        dependentOnFieldType: 'radio'
      },
      // {
      //   label: 'CSR Expiry Date',
      //   type: 'date',
      //   inputtype: 'date',
      //   // dateFormat: 'YYYY-MM-DD hh:mm:ss',
      //   minDate: String(new Date().getFullYear() - 5) + '-' + '01-01',
      //   maxDate: String(new Date().getFullYear() + 5) + '-' + '12-31',
      //   placeholder: `Select Date`,
      //   showrequired: 'true',
      //   validation: 'required',
      //   jsonfield: 'csr1_expiry_date',
      //   validationMsg: 'Please enter valid CSR expiry date registration number',
      //   wrapperKey: 'legal_compliance',
      //   subWrapperKey: '',
      //   formField: true,
      //   disableDependentOn: 'is_csr1_registered',
      //   dependentOnFieldType: 'radio'
      // },
      {
        label: 'Upload the copy of CSR1 Document',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        jsonfield: 'csr1_registration_certificate',
        validationMsg: 'Please upload CSR1 document',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        formField: true,
        disableDependentOn: 'is_csr1_registered',
        disableRelatedField: '',
        dependentOnFieldType: 'radio'
      }
    ]
  },
  {
    title: 'Is your NGO registred under FCRA Act',
    description: 'FCRA is used to raise foreign funds',
    wrapperKey: 'financial_details',
    step: 'step_5',
    fields: [
      {
        label: '',
        inputtype: 'radio',
        placeholder: '',
        jsonfield: 'is_fcra_certified',
        validationMsg: 'Please enter your option',
        dependentFields: [
          'fcra_registration_number',
          'fcra_purpose_id',
          'fcra_expiry_date',
          'fcra_certificate'
        ],
        wrapperKey: 'financial_capacity',
        subWrapperKey: 'finance',
        formField: true,
        disableDependentOn: ''
      },
      {
        label: 'FCRA Registration Number',
        subWrapperKey: '',
        type: 'number',
        inputtype: 'text',
        placeholder: `e.g., 123456789`,
        validationpattern: /[0-9]{9}$/,
        lengthMin: 9,
        maxLength: 9,
        validpatternformat: '123456789',
        jsonfield: 'fcra_registration_number',
        validation: 'required',
        validationMsg:
          'Please enter valid FCRA registration number in the format eg 123456789',
        wrapperKey: 'financial_capacity',
        subWrapperKey: 'finance',
        textContent: '',
        // textContent: 'uppercase',
        formField: true,
        disableDependentOn: 'is_fcra_certified',
        dependentOnFieldType: 'radio'
      },
      // {
      //   separator: false,
      //   // heading: 'Registration',
      //   label: 'Purpose Under Which FCRA License Is Guaranteed',
      //   inputtype: 'multiselect',
      //   validation: 'required',
      //   dropdownType: 'multiple',
      //   jsonfield: 'fcra_purpose_id',
      //   options: {
      //     key: 'legalStructureDropdown.legalStructure',
      //     valuekey: 'id',
      //     displaykey: 'name'
      //   },
      //   masterfield: 'legal_structure',
      //   validationMsg: 'Please enter valid FCRA registration number'
      // },
      {
        label: 'Purpose under which FCRA License is Guaranteed',
        inputtype: 'multiselect',
        validationMsg: 'Please select purpose',
        // dropdownType: 'single',
        options: {
          key: 'common.fcraPurpose',
          valuekey: 'id',
          displaykey: 'name'
        },
        masterfield: 'fcra_purpose_id',
        showrequired: true,
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        newLine: true,
        jsonfield: 'fcra_purpose_id',
        wrapperKey: 'financial_capacity',
        subWrapperKey: 'finance',
        formField: true,
        disableDependentOn: 'is_fcra_certified',
        dependentOnFieldType: 'radio'
      },
      {
        label: 'FCRA Expiry Date',
        type: 'date',
        inputtype: 'date',
        // dateFormat: 'YYYY-MM-DD hh:mm:ss',
        minDate: String(new Date().getFullYear() - 5) + '-' + '01-01',
        maxDate: String(new Date().getFullYear() + 5) + '-' + '12-31',
        placeholder: `Select Date`,
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        showrequired: true,
        jsonfield: 'fcra_expiry_date',
        validationMsg: 'Please enter FCRA expiry date',
        wrapperKey: 'financial_capacity',
        subWrapperKey: 'finance',
        formField: true,
        disableDependentOn: 'is_fcra_certified',
        dependentOnFieldType: 'radio'
      },
      {
        label: 'Upload FCRA Document',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        jsonfield: 'fcra_certificate',
        validationMsg: 'Please upload FCRA document',
        wrapperKey: 'financial_capacity',
        subWrapperKey: 'finance',
        formField: true,
        disableDependentOn: 'is_fcra_certified',
        disableRelatedField: '',
        dependentOnFieldType: 'radio'
      }
    ]
  }
];

export const preRegistrationDataForm2 = [
  {
    title: 'Audited Financial Reports',
    tooltip:
      'Each audited financial statement should include an audit report, balance sheet, income & expenditure, receipts and payments, and notes of account for the last three financial years.',
    description: 'Upload the Audited financial reports of last 3 years.',
    wrapperKey: 'audit_item',
    step: 'step_1',
    fields: [
      {
        label:
          'Financial Report of ' +
          String(financialYears[0]) +
          '-' +
          String(financialYears[0] + 1),
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        jsonfield: 'report_' + String(financialYears[0]),
        validationMsg:
          'Please upload financial report of ' +
          String(financialYears[0]) +
          '-' +
          String(financialYears[0] + 1),

        wrapperKey: 'financial_capacity',
        subWrapperKey: 'external_audit_report',
        formField: true,
        disableDependentOn: 'is_report_' + String(financialYears[0]),
        dependentOnFieldType: 'checkbox',
        disableRelatedField: '',
        yearFlag: 1
      },
      {
        label:
          'We did not conduct a financial audit for ' +
          String(financialYears[0]) +
          '-' +
          String(financialYears[0] + 1),
        inputtype: 'tncCheckBox',
        jsonfield: 'is_report_' + String(financialYears[0]),
        dependentFields: ['report_' + String(financialYears[0])],
        wrapperKey: '',
        subWrapperKey: '',
        formField: false,
        yearFlag: 1
      },
      {
        label:
          'Financial Report of ' +
          String(financialYears[1]) +
          '-' +
          String(financialYears[1] + 1),
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        jsonfield: 'report_' + String(financialYears[1]),
        validationMsg:
          'Please upload financial report of ' +
          String(financialYears[1]) +
          '-' +
          String(financialYears[1] + 1),

        wrapperKey: 'financial_capacity',
        subWrapperKey: 'external_audit_report',
        formField: true,
        disableDependentOn: 'is_report_' + String(financialYears[1]),
        dependentOnFieldType: 'checkbox',
        disableRelatedField: '',
        yearFlag: 2
      },
      {
        label:
          'We did not conduct a financial audit for ' +
          String(financialYears[1]) +
          '-' +
          String(financialYears[1] + 1),
        inputtype: 'tncCheckBox',
        jsonfield: 'is_report_' + String(financialYears[1]),
        dependentFields: ['report_' + String(financialYears[1])],
        wrapperKey: '',
        subWrapperKey: '',
        formField: false,
        yearFlag: 2
      },
      {
        label:
          'Financial Report of ' +
          String(financialYears[2]) +
          '-' +
          String(financialYears[2] + 1),
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        jsonfield: 'report_' + String(financialYears[2]),
        validationMsg:
          'Please upload financial report of ' +
          String(financialYears[2]) +
          '-' +
          String(financialYears[2] + 1),

        wrapperKey: 'financial_capacity',
        subWrapperKey: 'external_audit_report',
        formField: true,
        disableDependentOn: 'is_report_' + String(financialYears[2]),
        disableRelatedField: '',
        dependentOnFieldType: 'checkbox',
        yearFlag: 3
      },
      {
        label:
          'We did not conduct a financial audit for ' +
          String(financialYears[2]) +
          '-' +
          String(financialYears[2] + 1),
        inputtype: 'tncCheckBox',
        jsonfield: 'is_report_' + String(financialYears[2]),
        dependentFields: ['report_' + String(financialYears[2])],
        wrapperKey: '',
        subWrapperKey: '',
        formField: false,
        yearFlag: 3
      }
    ]
  },
  {
    title: 'Tax Deduction Account Details',
    description: '',
    wrapperKey: 'tan_item',
    step: 'step_2',
    fields: [
      {
        label: 'Tax Deduction Account Number(TAN)',
        inputtype: 'text',
        type: 'text',
        lengthMin: 10,
        maxLength: 10,
        validationpattern: /[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
        validpatternformat: 'ABCD12345E',
        showrequired: 'true',
        validation: 'required',
        placeholder: 'e.g. ABCD12345E',
        validationMsg:
          'Please enter valid TAN number in the format eg ABCD12345E',
        jsonfield: 'tan_num',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        textContent: 'uppercase',
        disableDependentOn: 'is_tan_num',
        dependentOnFieldType: 'checkbox',
        formField: false
      },
      {
        label: 'Tax Deduction Account Document',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        jsonfield: 'tan_document',
        validationMsg: 'Please upload tax deduction account document',
        wrapperKey: 'legal_compliance',
        disableDependentOn: 'is_tan_num',
        dependentOnFieldType: 'checkbox',
        disableRelatedField: ['tan_num'],
        subWrapperKey: '',
        formField: false
      },
      {
        label: 'We do not have TAX Account Number',
        inputtype: 'tncCheckBox',
        jsonfield: 'is_tan_num',
        dependentFields: ['tan_num', 'tan_document'],
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        formField: false
      }
    ]
  },
  {
    title: 'GST Details',
    description: '',
    wrapperKey: 'gst_item',
    step: 'step_3',
    fields: [
      {
        label: 'GST Number',
        inputtype: 'text',
        type: 'text',
        lengthMin: 15,
        maxLength: 15,
        validationpattern: /[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
        validpatternformat: '22AAAAA0000A1Z5',
        showrequired: false,
        validation: '',
        placeholder: 'e.g. 22AAAAA0000A1Z5',
        validationMsg:
          'Please enter valid GST number in the format eg 22AAAAA0000A1Z5',
        jsonfield: 'gst_num',
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        textContent: 'uppercase',
        disableDependentOn: 'is_gst_num',
        dependentOnFieldType: 'checkbox',
        formField: false
      },
      {
        label: 'GST Registration - Scanned Copy',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'dragAndDrop',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: false,
        jsonfield: 'gst_cert',
        validationMsg: 'Please upload valid GST document',
        wrapperKey: 'legal_compliance',
        disableDependentOn: 'is_gst_num',
        dependentOnFieldType: 'checkbox',
        disableRelatedField: ['gst_num'],
        subWrapperKey: '',
        formField: false
      },
      {
        label: 'We do not have GST Number',
        inputtype: 'tncCheckBox',
        jsonfield: 'is_gst_num',
        dependentFields: ['gst_num', 'gst_cert'],
        wrapperKey: 'legal_compliance',
        subWrapperKey: '',
        formField: false
      }
    ]
  }
];
